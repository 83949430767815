/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> Main CSS <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// CSS Imports
@import './business.scss';

// Roboto~DEFAULT
@font-face {
font-family: 'default';font-weight: normal;font-style: normal;
src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype'); 
}
@font-face {
font-family: 'default';font-weight: bold;font-style: normal;
src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype'); 
}
@font-face {
font-family: 'default';font-weight:normal;font-style:italic;
src: url('../fonts/roboto/Roboto-Italic.ttf') format('truetype'); 
}
@font-face {
font-family: 'default';font-weight:bold;font-style:italic;
src: url('../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'); 
}

// GrandHotel~LOGO
@font-face {
font-family: 'logo';font-weight: normal;font-style: normal;
src: url('../fonts/grandHotel/GrandHotel-Regular.ttf') format('truetype'); 
}

// Default variables
$delay:0.2s;
$paddingLarge:20px;
$textColor: #5B6A71;
$borderColor2:#dddddd;
$color2: #66148C;

body {color:$textColor}

// Default document CSSs
h1 {margin:0px;padding:0px}
h2 {margin:0px;padding:0px}
h3 {margin:0px;padding:0px}

.celogo {font-family: "logo";font-size: 100px;border-radius: 10px;background: linear-gradient(#fff,#888);color:#000;
display: flex;justify-content: center;text-align: center;margin: auto;padding:20px 20px 10px 10px;width:80px;height:50px;
}




.stdbox {
        background: linear-gradient(195deg, #fff, #fff);
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        padding:10px 20px 10px;border-radius: 10px;color:#fff;
        color:$textColor;
}
.stdbox_2 {
        background: linear-gradient(195deg, #fff, #fff);
        padding:10px 20px 10px;border-radius: 10px;color:#fff;
        color:$textColor;
}
.stdbox_3 {
        background: linear-gradient(195deg, #fff, #fff);
        //box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        padding:10px 20px 10px;border-radius: 0px;color:#fff;
        color:$textColor;
}


h1 {color:#7C0533}
input {width:200px;border-radius:5px;padding:10px;text-decoration: none; font-size: 15px;outline: none;border:0px solid lighten($borderColor2,5);
        background: lighten($borderColor2,5);color:$textColor;}
      input:focus {text-decoration: none;background: darken($borderColor2,2);}
      
      textarea {width:200px;border-radius:5px;padding:10px;text-decoration: none; font-size: 15px;outline: none;border:0px solid lighten($borderColor2,5);
        background: lighten($borderColor2,5);color:$textColor;}
      textarea:focus {text-decoration: none;background: darken($borderColor2,2);}
      select {width:220px;border-radius:5px;padding:10px;text-decoration: none; border:0px solid $borderColor2;font-size: 15px;outline: none;background: lighten($borderColor2,5);color:$textColor;}
      select:focus {text-decoration: none;border:0px solid lighten($color2,30);background: darken($borderColor2,2);}
      